<template>
  <div class="o-header">
    <router-link to="/">
      <div class="o-header__container">
        <div class="app__container--boxed">
          <img
            src="../../assets/logo.svg"
            alt="Itonomy logo"
            width="209"
            height="50"
          />
        </div>
      </div>
      <transition
        :name="allowTransition ? 'fade' : ''"
        @before-enter="onBeforeEnter"
      >
        <div
          v-show="$route.path === '/'"
          class="o-header__container"
        >
          <div class="app__container--boxed">
            <img
              src="../../assets/logo-dark.svg"
              alt="Itonomy logo"
              width="209"
              height="50"
            />
          </div>
        </div>
      </transition>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'OHeader',
  data () {
    return {
      allowTransition: false
    }
  },
  methods: {
    onBeforeEnter () {
      this.allowTransition = true;
    }
  }
}
</script>

<style lang="scss">
  .o-header {
    background-color: var(--c-primary);
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;

    &__container {
      width: 100%;
      overflow: hidden;

      &:nth-child(2) {
        position: absolute;
        transform: translateX(30%);
        top: 0;
        background-color: var(--c-light-green);

        @media (min-width: 768px) {
          transform: translateX(calc((100% - 768px) / 2 + 768px * 0.3));
        }

        > div {
          transform: translateX(-30%);
          margin-left: 0;
        }
      }
    }

    img {
      margin: var(--spacer-sm) 0;
    }
  }
</style>
