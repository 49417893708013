import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
const LoginView = () => import('@/views/LoginView.vue');
const AccountView = () => import('@/views/AccountView.vue');
const DashboardView = () => import('@/views/DashboardView.vue');
const BoardView = () => import('@/views/BoardView.vue');
const SuccessView = () => import('@/views/SuccessView.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/board',
    name: 'board',
    component: BoardView
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
