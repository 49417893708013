<template>
  <div
    :class="{
    'a-toggle': true,
    'a-toggle--selected': toggled
    }"
  >
    <div
      :id="'toggle-' + uuid"
      class="a-toggle__toggle"
    >
      <div
        class="a-toggle__track"
        @click="toggle"
      >
        <div class="a-toggle__knob" />
      </div>
    </div>
    <label
      :for="'toggle-' + uuid"
      v-if="label"
      class="a-toggle__label"
      @click="toggle"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'AToggle',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      toggled: this.value,
      uuid: null
    }
  },
  watch: {
    value () {
      this.toggled = this.value;
    }
  },
  created () {
    this.uuid = Math.round(Math.random() * 1000);
  },
  methods: {
    toggle () {
      this.toggled = !this.value;
      this.$emit('input', !this.value);
    }
  }
}
</script>

<style scoped lang="scss">
  .a-toggle {
    display: var(--toggle-display, flex);
    align-items: var(--toggle-align-items, center);
    gap: var(--toggle-gap, var(--spacer-xs));
    --toggle-knob-width: 20px;

    &__track {
      width: var(--toggle-track-width, 40px);
      height: var(--toggle-track-height, 20px);
      background: var(--toggle-track-background-color, white);
      border-radius: var(--toggle-track-border-radius, 10px);
      position: var(--toggle-track-position, relative);
      transition: var(--toggle-track-transition, background-color 150ms ease);
    }

    &__knob {
      cursor: pointer;
      width: var(--toggle-knob-width, 20px);
      height: var(--toggle-knob-height, 20px);
      background: var(--toggle-knob-background-color, var(--c-primary));
      border-radius: var(--toggle-knob-border-radius, 10px);
      box-shadow: var(--toggle-knob-box-shadow, 0 2px 4px rgba(148,148,148,0.5));
      //transition: var(--toggle-knob-transition, margin-left 150ms ease);
      transition: all 150ms ease;
      &:active {
        --toggle-knob-width: 25px;
        transition: all 150ms ease;
      }
    }

    &--selected &__track {
      background: var(--toggled-selected-track-background-color, var(--c-dark-blue));
      transition: var(--toggle-selected-track-transition, var(--toggle-track-transition, background-color 150ms ease));
    }

    &--selected &__knob {
      margin-left: var(--toggle-knob-margin-left, calc(100% - var(--toggle-knob-width) + 1px));
      transition: all 150ms ease;
    }

    &__toggle {
      margin-right: var(--toggle-toggle-margin-right, var(--spacer-xs));
    }

    &__label {
      font-size: var(--toggle-label-font-size, var(--font-size--xs));
    }
  }
</style>
