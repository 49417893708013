import { BoothLocation, BoothState } from '@/modules/booth/booth';
import { Commit } from 'vuex';
import { get, ref, set } from 'firebase/database';
import { firebaseDatabase } from '@/plugins/firebaseApp';

const BoothModule = {
    namespaced: true,
    state: (): BoothState => ({
        location: {
            lat: '',
            long: ''
        }
    }),
    getters: {
        getLocation: (state: BoothState) => state.location
    },
    mutations: {
      setLocation (state: BoothState, location: BoothLocation) {
          state.location.lat = location.lat;
          state.location.long = location.long;
      }
    },
    actions: {
        setLocation ({ commit }: { commit: Commit }, location: BoothLocation) {
            commit('setLocation', location);
            set(ref(firebaseDatabase, '/booth'), location);
        },
        getLocation ({ commit }: { commit: Commit }) {
            return get(ref(firebaseDatabase, '/booth'))
                .then(snapshot => {
                    commit('setLocation', snapshot.val());
                });
        }
    }
}

export default BoothModule;
