let currentPosition = 0;

export const disableBodyScroll = () => {
    const tmpScrollY = window.scrollY;
    currentPosition = window.scrollY;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${tmpScrollY}px`;
    document.body.style.width = '100vw';
}

export const clearBodyScrollLock = () => {
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    window.scrollTo(0, currentPosition);
}
