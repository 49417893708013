import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { createStore } from 'vuex';
import UserModule from '@/modules/user';
import UiModule from '@/modules/ui';
import { createMetaManager, defaultConfig, plugin as VueMetaPlugin } from 'vue-meta';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill';
import BoothModule from '@/modules/booth';

const store = createStore({
    modules: {
        user: UserModule,
        ui: UiModule,
        booth: BoothModule
    }
});

const metaManager = createMetaManager(true, {
    ...defaultConfig,
    meta: { tag: 'meta', nameless: true }
});

smoothscroll.polyfill();

createApp(App)
    .use(store)
    .use(router)
    .use(metaManager)
    .use(VueMetaPlugin)
    .mount('#app');
