import { Commit } from 'vuex';
import { UiState } from '@/modules/ui/ui';

const UiModule = {
    namespaced: true,
    state: (): UiState => ({
        activeModal: '',
        modalData: {}
    }),
    getters: {
        getActiveModal: (state: UiState) => state.activeModal,
        getModalData: (state: UiState) => state.modalData,
    },
    mutations: {
        setActiveModal (state: UiState, modalName: string) {
            state.activeModal = modalName;
        },
        setModalData (state: UiState, modalData: { [key: string]: never }) {
            state.modalData = modalData;
        }
    },
    actions: {
        openModal ({ commit }: { commit: Commit }, { name, modalData }: { name: string, modalData?: { [key: string]: never } }) {
            commit('setActiveModal', name);
            commit('setModalData', modalData);
        },
        closeModal ({ commit }: { commit: Commit }) {
            commit('setActiveModal', '');
            commit('setModalData', {});
        }
    }
}

export default UiModule;
