<template>
  <div class="m-modal-love">
    <h3>{{ modalData.title }}</h3>
    <p>{{ modalData.body }}</p>
  </div>
</template>

<script>
export default {
  name: 'MModalLove',
  props: {
    modalData: {
      type: Object,
      default: () => ({
        title: '',
        body: ''
      })
    }
  }
}
</script>

<style lang="scss">
  .m-modal-love {
    text-align: center;
  }
</style>
