import { ref, set, get, onValue } from 'firebase/database';
import { firebaseDatabase } from '@/plugins/firebaseApp';
import { User, UserState } from '@/modules/user/user';
import { ActionContext, Commit, Dispatch } from 'vuex';
import { sha1 } from 'crypto-hash';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const debounce = require('lodash.debounce');

const UserModule = {
    namespaced: true,
    state: (): UserState => ({
        me: {
            name: '',
            anonymous: false,
            color: '',
            id: '',
            inflated: false,
            title: '',
            online: false,
            vip: false,
            hasLoved: false,
            hasCompleted: false
        },
        list: [],
        diffQueue: [],
        likes: 0
    }),
    getters: {
        getMe: (state: UserState) => state.me,
        getList: (state: UserState) => state.list,
        getDiffQueue: (state: UserState) => state.diffQueue,
        getLikes: (state: UserState) => state.likes
    },
    mutations: {
        setMe (state: UserState, me: User) {
            state.me = me;
        },
        setList (state: UserState, list: User[]) {
            state.list = list;
        },
        queueDiffs (state: UserState, list: User[]) {
            if (!list) return;
            if (!state.list) {
                state.diffQueue.push(...Object.keys(list));
                return;
            }

            const diffs = Object.keys(list).filter(userId => !Object.keys(state.list).includes(userId));
            state.diffQueue.push(...diffs);
        },
        deleteFromDiffQueue (state: UserState, diff: string) {
            state.diffQueue.splice(state.diffQueue.indexOf(diff), 1);
        },
        setLikes (state: UserState, likes: number) {
            state.likes = likes;
        }
    },
    actions: {
        async addUser ({ commit }: { commit: Commit }, { name, id, color = '', anonymous = false }: { name: string, id: string, color: string, anonymous?: boolean }) {
            if (!name || !id) return;

            id = await sha1(id);
            const user = {
                id,
                name,
                color,
                anonymous,
                inflated: false
            }

            window.localStorage.setItem('userId', id);

            const snapshot = await get(ref(firebaseDatabase, 'users/' + id));
            let currentUser = snapshot.val();
            if (!currentUser) {
                await set(ref(firebaseDatabase, 'users/' + id), user);
                currentUser = user;
            }

            commit('setMe', currentUser);
        },
        async updateUser ({ commit, state, dispatch }: { commit: Commit, state: UserState, dispatch: Dispatch }, userUpdates: { [key: string]: string | boolean } = {}) {
            const userId = userUpdates.id || state.me.id;
            const snapshot = await get(ref(firebaseDatabase, 'users/' + userId));
            const currentUser = snapshot.val();

            const updatedUser = Object.assign(currentUser, userUpdates);
            commit('setMe', updatedUser);
            dispatch('debouncedUpdate', updatedUser);
        },
        debouncedUpdate: debounce(
            (context: ActionContext<UserState, never>, updatedUser: User) => {
                return set(ref(firebaseDatabase, 'users/' + updatedUser.id), updatedUser);
            },
            500,
            { trailing: true }
        ),
        getList ({ commit }: { commit: Commit }) {
            const usersRef = ref(firebaseDatabase, 'users');
            let queueDiffs = false;
            onValue(usersRef, snapshot => {
                const users = snapshot.val();
                if (queueDiffs) {
                    commit('queueDiffs', users);
                }

                commit('setList', users);
                queueDiffs = true;
            });
        },
        deleteFromDiffQueue ({ commit }: { commit: Commit }, diff: string) {
            commit('deleteFromDiffQueue', diff);
        },
        getMe ({ commit }: { commit: Commit }, id: string) {
            return get(ref(firebaseDatabase, 'users/' + id))
                .then(snapshot => {
                    const user = snapshot.val();
                    if (user) {
                        commit('setMe', user);
                    }
                });
        },
        async sendLove () {
            const loveRef = ref(firebaseDatabase, 'love');
            let counter = (await get(loveRef))?.val();
            counter++;
            set(loveRef, counter);
        },
        setupLoveListener ({ commit }: { commit: Commit }) {
            onValue(ref(firebaseDatabase, 'love'), snapshot => {
                commit('setLikes', snapshot.val());
            });
        }
    }
};

export default UserModule;
