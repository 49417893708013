<template>
  <div class="m-modal-scanner">
    <h3>{{ 'Scan de QR-code van je persoonlijke badge 🤳' }}</h3>
    <video id="video" ref="video" muted playsinline />
    <transition
      name="expand"
      @enter="onEnter"
    >
      <p
        v-if="showError"
        class="m-modal-scanner__error"
      >
        We hebben een ongeldige QR-code gedetecteerd. Dubbelcheck de scaninstructies en controleer of je de juiste QR-code voor je hebt.
      </p>
    </transition>
  </div>
</template>

<script>
import { BarcodeFormat, BrowserQRCodeReader } from '@zxing/browser';
import { mapActions, mapGetters } from 'vuex';
import { generateRandomColor } from '@/helpers/generators';

export default {
  name: 'MModalScanner',
  props: {
    modalData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      codeReader: null,
      controls: null,
      showError: false
    }
  },
  mounted () {
    this.initVideo();
  },
  methods: {
    ...mapActions({
      addUser: 'user/addUser',
      closeModal: 'ui/closeModal',
      updateUser: 'user/updateUser'
    }),
    async initVideo () {
      const formats = [ BarcodeFormat.QR_CODE ];
      const hints = new Map([
        [ 2, formats ],
        [ 3, true ]
      ]);

      this.codeReader = new BrowserQRCodeReader(
          hints,
          {
            delayBetweenScanSuccess: 3000
          }
      );

      // eslint-disable-next-line
      this.controls = await this.codeReader.decodeFromVideoDevice(undefined, 'video', result => {
        if (result) {
          if (result.text.trim().match(/.+\s+.+/)) {
            this.closeModal();
            this.handleLogin(result);
            this.modalData.callback();
            this.controls.stop();
          } else {
            this.showError = true;
          }
        }
      });
    },
    handleLogin (result) {
      let name = result.text.trim();
      name = name.split(/\s/);
      name = name[name.length - 1];
      name = name.substring(1);
      this.addUser({
        name,
        id: result.text.trim(),
        color: generateRandomColor()
      });

      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
          this.$router.push('/account');
        }, 800);
      });
    },
    onEnter (el) {
      el.style.setProperty('height', 'auto');
      const targetHeight = Math.ceil(el.offsetHeight) + 'px';
      el.style.setProperty('height', '0');

      setTimeout(() => {
        requestAnimationFrame(() => {
          el.style.setProperty('height', targetHeight);
        });
      }, 33);

      setTimeout(() => {
        el.style.setProperty('height', 'auto');
      }, 250);
    }
  }
}
</script>

<style lang="scss">
  .m-modal-scanner {
    video {
      width: 100%;
      border-radius: 20px;
      overflow: hidden;
    }

    &__error {
      color: red;
      font-size: 14px;
    }

    .expand {
      &-enter-active,
      &-leave-active {
        transition: height 0.25s ease-in-out;
        overflow: hidden;
      }
    }
  }
</style>
