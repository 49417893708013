<template>
  <div class="o-preferences-bar">
    <div class="o-preferences-bar__spacer"/>
    <div class="o-preferences-bar__sticky-bar app__container">
      <label
        for="is-anonymous"
        class="o-preferences-bar__preference"
        @click.stop.capture="$refs.anonymousToggle.toggle"
      >
        <a-toggle
          name="anonymous"
          id="is-anonymous"
          ref="anonymousToggle"
          :value="getMe.anonymous"
          @input="handleAnonymousInput"
        />
        <span>Anoniempje</span>
      </label>
      <label
        for="color"
        class="o-preferences-bar__preference button--pure"
      >
        <input
          type="color"
          name="color"
          id="color"
          :value="getMe.color || '#E3F3F1'"
          @input="handleColorInput"
        />
        <span>
          Kleur
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AToggle from '@/components/atoms/a-toggle';

export default {
  name: 'OPreferencesBar',
  components: {
    AToggle
  },
  computed: {
    ...mapGetters({
      getMe: 'user/getMe'
    })
  },
  methods: {
    ...mapActions({
      updateUser: 'user/updateUser'
    }),
    handleAnonymousInput (anonymous) {
      this.updateUser({ anonymous });
    },
    handleColorInput (e) {
      this.updateUser({ color: e.target.value });
    }
  }
}
</script>

<style lang="scss">
  .o-preferences-bar {
    z-index: 3;
    position: relative;

    &__spacer {
      height: 80px;
      width: 100%;
    }

    &__sticky-bar {
      height: 80px;
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      background-color: var(--c-blue);
      box-shadow: 0 -2px 3px rgba(0,0,0,0.1);
      transform: var(--preferences-bar-transform, translateY(0));
      transition: transform .2s ease .5s;
    }

    &__preference {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: var(--spacer-xs);
      gap: var(--spacer-xs);

      .a-toggle {
        flex-direction: column;
      }
    }

    label {
      font-weight: var(--font-weight--medium);
    }
  }
</style>
