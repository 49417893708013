<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Itonomy` : `Itonomy` }}</template>
  </metainfo>
  <o-header v-if="$route.path.indexOf('/board') === -1" />
  <div class="app__pages">
    <router-view v-slot="{ Component }">
      <transition
        :name="enableTransition ? transitionName : ''"
        @before-enter="onBeforeEnter"
      >
        <component
          :is="Component"
          class="app__page"
          @hide-preferences="hidePreferences = true"
        />
      </transition>
    </router-view>
  </div>
  <o-modals :modal-name="modalName" />
  <transition name="bottom-slide">
    <o-preferences-bar
      v-if="!hidePreferences && $route.path.indexOf('account') !== -1"
    />
  </transition>
</template>

<script>
import OHeader from '@/components/organisms/o-header';
import OModals from '@/components/organisms/o-modals';
import { mapGetters } from 'vuex';
import OPreferencesBar from '@/components/organisms/o-preferences-bar';

export default {
  name: 'App',
  components: {
    OPreferencesBar,
    OModals,
    OHeader
  },
  data () {
    return {
      enableTransition: false,
      hidePreferences: false
    }
  },
  computed: {
    ...mapGetters({
      modalName: 'ui/getActiveModal',
      getMe: 'user/getMe'
    }),
    transitionName () {
      return 'side-slide';
    }
  },
  methods: {
    onBeforeEnter () {
      // This prevents the transition that happens on page load
      this.enableTransition = true;
    }
  },
  metaInfo () {
    return {
      title: 'Home',
      meta: [
        {
          vmid: 'theme-color',
          name: 'theme-color',
          content: '#64C5B4'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
  @import "src/style/main";

  #app {
    font-family: var(--font-family--secondary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .app__page {
    background-color: var(--c-primary);
    display: inline-block;
    backface-visibility: hidden;
    width: 100%;

    &s {
      overflow: hidden;
    }
  }

  .side-slide {
    &,
    &-back {
      &-enter-active,
      &-leave-active {
        overflow: hidden;
        transform-origin: center 50vh;
        position: absolute;
        top: 86px;
        left: 0;
        --_slide-border-radius: 20px;
        --_slide-filter: drop-shadow(0 0 20px rgba(0,0,0,0.1));
      }
    }

    &-enter-active {
      animation: slideIn .8s ease;
    }

    &-leave-active {
      animation: slideOut .8s ease;
    }

    &-back {
      &-enter-active {
        animation: slideOut reverse .8s ease;
      }

      &-leave-active {
        animation: slideIn reverse .8s ease;
      }
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateX(100vw);
      border-radius: 0;
    }
    30% {
      transform: translateX(80vw) scale(0.8);
      border-radius: var(--_slide-border-radius);
      filter: var(--_slide-filter);
    }
    70% {
      transform: translateX(20vw) scale(0.8);
      border-radius: var(--_slide-border-radius);
      filter: var(--_slide-filter);
    }
    100% {
      transform: translateX(0);
      border-radius: 0;
    }
  }

  @keyframes slideOut {
    0% {
      transform: translateX(0);
      border-radius: 0;
    }
    30% {
      transform: translateX(-20vw) scale(0.8);
      border-radius: var(--_slide-border-radius);
      filter: var(--_slide-filter);
    }
    70% {
      transform: translateX(-80vw) scale(0.8);
      border-radius: var(--_slide-border-radius);
      filter: var(--_slide-filter);
    }
    100% {
      transform: translateX(-100vw);
      border-radius: 0;
    }
  }

  .bottom-slide {
    &-enter-to,
    &-leave-from {
      --preferences-bar-transform: translateY(0);
    }

    &-leave-to,
    &-enter-from {
      --preferences-bar-transform: translateY(100%);
    }
  }
</style>
