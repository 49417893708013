<template>
  <transition name="fade">
    <div
      v-if="modalName"
      class="o-modals"
      ref="modal"
    >
      <div class="o-modals__modal">
        <div
          class="o-modals__overlay"
          @click="closeModal"
        />
        <div class="o-modals__content">
          <component
            :is="modalName"
            :modal-data="modalData"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import MModalScanner from '@/components/molecules/modals/m-modal-scanner';
import MModalText from '@/components/molecules/modals/m-modal-text';
import { clearBodyScrollLock, disableBodyScroll } from '@/helpers/scroll-lock';

export default {
  name: 'OModals',
  components: {
    MModalText,
    MModalScanner
  },
  props: {
    modalName: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      modalData: 'ui/getModalData'
    })
  },
  watch: {
    modalName () {
      if (this.modalName) {
        this.$nextTick(() => {
          disableBodyScroll();
        });
      } else {
        clearBodyScrollLock();
      }
    }
  },
  methods: {
    closeModal () {
      this.$store.dispatch('ui/closeModal');
    }
  }
}
</script>

<style lang="scss">
  .o-modals {
    z-index: 5;
    position: relative;

    &__modal,
    &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    }

    &__overlay {
      background-color: rgba(0,0,0,0.4);
      backdrop-filter: blur(3px);
    }

    &__content {
      position: absolute;
      color: var(--c-text-contrast);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - var(--spacer-sm) * 2);
      padding: var(--spacer-sm);
      background-color: var(--c-white);
      border-radius: 20px;
      box-sizing: border-box;
    }
  }
</style>
