const generateColorComponent = () => {
    return Math.round((Math.random() * 255)).toString(16).padStart(2, '0');
}

export const generateRandomColor = () => {
    let color = '#';
    for (let i = 0; i < 3; i++) {
        color += generateColorComponent();
    }

    return color;
}
